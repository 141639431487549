import { useState, useEffect, useContext } from 'react';
import { getFirestore, collection, getDocs, updateDoc } from 'firebase/firestore/lite';
import Select from 'react-select';
import { FirebaseContext } from '../../firebaseContext';
import './cadastrar.css';

import Loading from '../../components/Loading';

export default function Cadastrar() {

  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState();
  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(false);

  const app = useContext(FirebaseContext);

  async function getRooms() {
    const db = getFirestore(app);

    const roomsCol = collection(db, 'comodos');
    const roomsSnapshot = await getDocs(roomsCol);
    const roomsList = roomsSnapshot.docs.map(doc => ({
      ref: doc.ref,
      ...doc.data()
    }));

    if(Array.isArray(roomsList)) {
      setRooms(roomsList);
    } 
  }

  useEffect(() => {
    getRooms();
  }, []);

  async function cadastrar(e){
    e.preventDefault();
    
    if(name && imageUrl && price && selectedRoom){
      setLoading(true);
      const currentRoom = rooms[selectedRoom.value];
      const newItem = {
        name,
        image: imageUrl,
        price,
        link : linkUrl,
      } 
      await updateDoc(currentRoom.ref, {
        name: currentRoom.name,
        items: [...currentRoom.items, newItem]
      }).then(() =>{
        setName('');
        setImageUrl('');
        setLinkUrl('');
        setPrice(0);
      }).catch(() => {
        alert('Não foi possível adicionar novo item');
      }).finally(() => {
        setLoading(false);
        getRooms();
      });
    }
  }

  return (
    <>
      <form className='cadastrar'>
        <Select
          onChange={r => setSelectedRoom(r)}
          options={rooms.map((room, index) => ({ value: index, label: room.name }))}/>
        <input
          placeholder='Nome do item'
          value={name} onChange={e => setName(e.currentTarget.value)}
        />
        <input
          placeholder='Link da imagem'
          value={imageUrl}
          onChange={e => setImageUrl(e.currentTarget.value)}
        />
        <input
          placeholder='Link da Loja'
          value={linkUrl}
          onChange={e => setLinkUrl(e.currentTarget.value)}
        />
        <div>
          R$ <input
            type="number"
            placeholder='0.00'
            value={price}
            onChange={e => setPrice(e.currentTarget.value)}
          />
        </div>
        <button onClick={cadastrar}>Cadastrar</button>
      </form>
      {loading && <Loading />}
    </>
  )
}
