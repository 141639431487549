import React from 'react';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBjj9SDeHv7SxZ6MAPf0IstE3S76atCBjs",
  authDomain: "cha-de-panela-the-victors.firebaseapp.com",
  projectId: "cha-de-panela-the-victors",
  storageBucket: "cha-de-panela-the-victors.appspot.com",
  messagingSenderId: "432157682592",
  appId: "1:432157682592:web:c88d41a539d71ba9af92c5"
};

export const app = initializeApp(firebaseConfig);

export const FirebaseContext = React.createContext(app);