import { useState, useEffect, useContext, useMemo } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore/lite";
import { FirebaseContext } from "../../firebaseContext";

import PixModal from "../../components/PixModal";

import "./home.css";
import Loading from "../../components/Loading";

export default function Home() {
  const [rooms, setRooms] = useState([]);
  const [selectedItem, setSeletedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const app = useContext(FirebaseContext);

  async function getRooms(db) {
    const roomsCol = collection(db, "comodos");
    const roomsSnapshot = await getDocs(roomsCol);
    const roomsList = roomsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
    }));

    if (Array.isArray(roomsList)) {
      setRooms(roomsList);
    }
  }

  function selectItem(room, item) {
    setSeletedItem({
      ...item,
      room,
    });
  }

  function checkItem(roomName, friendData) {
    setLoading(true);
    const currentRoom = rooms.find((r) => r.name === roomName);
    if (currentRoom) {
      getDoc(currentRoom.ref)
        .then((res) => {
          const updatedRoom = res.data();
          const modifyingIndex = updatedRoom.items.findIndex(
            (item) => item.name === selectedItem.name && !item.friendName
          );
          if (modifyingIndex > -1) {
            updatedRoom.items[modifyingIndex] = {
              ...updatedRoom.items[modifyingIndex],
              friendName: friendData.name,
              message: friendData.message,
            };
            updateDoc(currentRoom.ref, {
              items: updatedRoom.items,
            })
              .then(() => {
                setSeletedItem(null);
              })
              .catch(() => {
                alert("Ocorreu algum erro, entre em contato com os anfitriões");
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            alert(
              "Talvez alguém tenha presenteado este item ao mesmo tempo que você. Entre em contato com os anfitriões"
            );
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (!loading) {
      const db = getFirestore(app);
      getRooms(db);
    }
  }, [app, loading]);

  const roomsWithItems = useMemo(() => {
    return rooms.filter((room) => room.items.length);
  }, [rooms]);

  return (
    <main>
      <ul className="rooms">
        <div className="presentation">
          <p>
            Se você recebeu esse link é porque faz parte da nossa história de
            alguma forma. E sabe que a Thais e Phil vão ter um neném!
          </p>
          <p>
            E pra comemorar a vinda do Miguel, nós vamos nos aproveitar do
            clássico ritual de chá de bebê, e você pode nos ajudar a viver esse
            amor completamente novo nesse momento.
          </p>
          <p>
            Contamos com vocês no dia da festa! E o presente você pode escolher
            entre fazer um pix, comprar o presente e levar no dia da festa, ou
            mandar entregar no endereço do convite.
          </p>
          <p>
            Ah, e se você comprar na loja, por favor, nos avise no whatsapp pra
            gente marcar aqui ;)
          </p>
        </div>
        {roomsWithItems.map((room) => (
          <li key={room.name}>
            <h2>{room.name}</h2>
            <ul className="room">
              {room.items.map((item) => (
                <li
                  key={item.name}
                  className={`item ${item.friendName ? "bought" : ""}`}
                >
                  <div className="productImage">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="info">
                    <h3>{item.name}</h3>
                    <p>R${item.price}</p>
                    <div className="buttons">
                      {item.friendName ? (
                        <span>Presenteado</span>
                      ) : (
                        <button onClick={() => selectItem(room.name, item)}>
                          Fazer Pix
                        </button>
                      )}
                      {item.friendName ? (
                        <span></span>
                      ) : (
                        <button
                          onClick={() => window.open(item.link, "_blank")}
                        >
                          Comprar
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      {selectedItem && (
        <PixModal
          item={selectedItem}
          cancel={() => setSeletedItem(null)}
          confirmPix={checkItem}
        />
      )}
      {loading && <Loading />}
    </main>
  );
}
