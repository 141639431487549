import React, { useState } from "react";
import "./PixModal.css";

export default function PixModal({ item, confirmPix, cancel }) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  function confirm() {
    if (name) {
      confirmPix(item.room, {
        name,
        message,
      });
    } else {
      alert("Informe o seu nome");
    }
  }

  return (
    <div className="pix-container">
      <div className="modal">
        <p>
          Para presentear o(a) <strong>{item.name}</strong> faça um pix para:{" "}
          <strong>21 96405-1498</strong>
        </p>
        <input
          placeholder="Seu nome todo"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <textarea
          placeholder="Deixa aqui sua mensagem"
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
        <div className="options">
          <button onClick={confirm} className="confirm">
            Já fiz meu pix!
          </button>
          <button onClick={cancel} className="cancel">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}
