import React from 'react';
import './Loading.css';

export default function Loading() {
  return (
    <div className='loading'>
      <span>Salvando...</span>
    </div>
  )
}
