import { Routes, Route } from "react-router-dom";
import chadepanela from './assets/chadepanela.png';
import './App.css';
import pattern from "./assets/tea-pattern.png";
import { FirebaseContext, app } from './firebaseContext';

import Home from './pages/Home';
import Cadastrar from "./pages/Cadastrar";

function App() {
  
  return (
    <div className="App">
      <header className="App-header" style={{backgroundImage: `url(${pattern})`}}>
        {/* <img src={chadepanela} className="App-logo" alt="logo" /> */}
        <div className="baby-shower">
          <h1 className="baby-title">🍼 Chá de Bebê do Miguel 🍼</h1>
        </div>
      </header>
      <FirebaseContext.Provider value={app}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cadastrar" element={<Cadastrar />} />
        </Routes>
      </FirebaseContext.Provider>
    </div>
  );
}

export default App;
